import React, { FormEvent } from "react";

import { RenderChildren, headingId } from "../../../../../shared/block-editor-data/react";
import { Block } from "../../../../../shared/block-editor-data/types";
import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import { useAsyncCallbackWithLoading } from "../../../hooks/useAsyncFunctionWithLoading";
import "../../../translations";
import BlockEditor from "../../design-system/BlockEditor/BlockEditor";
import { getTextContent } from "../../design-system/BlockEditor/utils/helpers";
import Button from "../../design-system/Button";
import Card from "../../design-system/Card";
import Container from "../../design-system/Container";
import CombinedHeader from "../../design-system/PageHeader/CombinedHeader";
import { useMainClass } from "../../global-furniture/AppShell";
import useBreadcrumbs from "../useBreadcrumbs";
import DeleteButton from "./DeleteButton";
import DocumentStatus, { DocumentLink } from "./DocumentStatus";
import DownloadReportButton from "./DownloadReportButton";
import DuplicateReportButton from "./DuplicateReportButton";
import PreviewButton from "./PreviewButton";
import PrivateLinkButton from "./PrivateLinkButton";
import PublishButton from "./PublishButton";
import PublishChangesButton from "./PublishChangesButton";
import UnpublishButton from "./UnpublishButton";
import { usePersistence } from "./persistence";
import styles from "./styles.module.scss";

export default function InsightsReportBuilder() {
  const { save, name, setName, contents, setContents, loading, saveFormRef, savedDocument: doc } = usePersistence();

  useMainClass(styles.Main);

  const headings = contents.root.children.filter((block: Block) => block.type === "heading");

  const [onSubmit, saving] = useAsyncCallbackWithLoading(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      await save();
    },
    [save],
  );

  const breadcrumbs = useBreadcrumbs({ siteTab: "insights" });

  return (
    <Container>
      <CombinedHeader
        className="ds-py-6"
        title={doc ? name : "New Insights report"}
        visuallyHideTitle
        breadcrumbs={breadcrumbs}
      >
        <PreviewButton />
        <PrivateLinkButton />
        <DuplicateReportButton />
        <DownloadReportButton />
        <DeleteButton />
      </CombinedHeader>
      <section>
        <form className={styles.content} onSubmit={onSubmit} ref={saveFormRef}>
          <Card className={styles.card__blockEditor} contentClassName="block">
            <BlockEditor defaultValue={contents} onChange={setContents} main autoFocus />
          </Card>

          <div className={styles.contentManager}>
            <Card compact className={styles.card__savePanel}>
              <DocumentStatus document={doc} />
              <DocumentLink document={doc} />
              <StringInput
                label="Document title"
                className="ds-mt-5"
                required
                onChange={setName}
                value={name}
                size="medium"
              />
              <ButtonSet direction="vertical" className="ds-mt-5">
                <Button type="submit" disabled={loading} loading={saving}>
                  Save changes
                </Button>
                <PublishButton />
                <ButtonSet align="stretch">
                  <PublishChangesButton />
                  <UnpublishButton />
                </ButtonSet>
              </ButtonSet>
            </Card>
            <Card compact className={styles.card__headerPanel}>
              <h4 className="ds-h3">Contents</h4>
              <ul className={styles.card__headerPanel_tableOfContents}>
                {headings.map((heading, i) =>
                  heading.type === "heading" && heading.children[0]?.text ? (
                    <li key={i}>
                      <a
                        href={`#${headingId(getTextContent(heading)) + i}`}
                        className={`${styles.insight_header} ${
                          heading.tag === "h1" || heading.tag === "h2" ? styles.h2 : styles.h3
                        }`}
                      >
                        <RenderChildren content={heading.children} />
                      </a>
                    </li>
                  ) : null,
                )}
              </ul>
            </Card>
          </div>
        </form>
      </section>
    </Container>
  );
}
