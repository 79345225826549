import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type ButtonSetProps =
  | { direction: "vertical"; align?: "start"; className?: string }
  | { direction?: "horizontal"; align?: "start" | "end" | "stretch" | "space-between"; className?: string };

/** Wrap a list of buttons in this to make them full-width and vertically stacked instead of the normal horizontal arrangement */
export default function ButtonSet({ direction, align, children, className }: PropsWithChildren<ButtonSetProps>) {
  return (
    <div
      className={`
        ${className ?? ""}
        ${styles.ButtonSet}
        ${styles[direction ?? "horizontal"]}
        ${styles[align ?? "start"]}
      `}
    >
      {children}
    </div>
  );
}

export function Spacer() {
  return <div className={styles.Spacer} />;
}
