import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useState } from "react";

import { ExportDataQuery } from "../../../../../__generated__/graphql";
import Alert from "../../../../../shared/components/design-system/Alert";
import Button from "../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { TextElement } from "../../../../../shared/components/translation";
import { importTranslationsBackground } from "../../../../../shared/translation/initialisation";
import { ContainerSize } from "../../design-system/Container";
import { ExportDownloads } from "../../design-system/ExportDownloads";
import english from "./translations/en-GB.json";

importTranslationsBackground("ExportAllDataButton", { "en-GB": english });

export const EXPORT_DATA_QUERY = gql`
  query exportData {
    dataExport {
      password
    }
  }
`;

export default function ExportAllDataButton() {
  const [exportRequested, setExportRequested] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { data, loading, error } = useQuery<ExportDataQuery>(EXPORT_DATA_QUERY, {
    skip: !exportRequested,
    fetchPolicy: "no-cache",
  });

  const openModal = useCallback(() => {
    mixpanel.track("Opened all-data export modal");
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    mixpanel.track("Closed all-data export modal");
    setModalOpen(false);
    setExportRequested(false);
  }, []);

  const startExport = useCallback(() => {
    mixpanel.track("Started all-data export");
    setExportRequested(true);
  }, []);

  return (
    <>
      <Button variant="secondary" onClick={openModal} icon="arrowToBottom" label="ExportAllDataButton:buttonLabel" />
      <Dialog
        size={ContainerSize.Small}
        isOpen={modalOpen}
        onClose={closeModal}
        isLoading={loading}
        loadingText="ExportAllDataButton:loadingText"
      >
        <SectionHeader title="ExportAllDataButton:buttonLabel" />
        {!exportRequested && !loading ? (
          <TextElement tag="p" className="mb-5" t="ExportAllDataButton:instructions" />
        ) : error ? (
          // TODO: translate error messages
          <Alert variant="danger" title="ExportAllDataButton:errorMessage" message={error.message} />
        ) : (
          !loading && (
            <>
              <TextElement tag="p" className="ds-mb-5" t="ExportAllDataButton:postInstructions" />
              <ExportDownloads password={data?.dataExport.password ?? ""} showZipInstructions />
            </>
          )
        )}
        <SectionFooter>
          <Button
            variant="primary"
            onClick={startExport}
            disabled={exportRequested}
            loading={loading}
            label={exportRequested ? "ExportAllDataButton:loadingButtonLabel" : "ExportAllDataButton:buttonLabel"}
          />
        </SectionFooter>
      </Dialog>
    </>
  );
}
