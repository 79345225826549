import { useFocusRing } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";

import Badge, { BadgeVariant } from "../../../../../shared/components/design-system/Badge";
import Icon, { IconSlug } from "../../../../../shared/components/design-system/Icon";
import { TextElement, Translatable } from "../../../../../shared/components/translation";
import styles from "./styles.module.scss";

export interface CheckboxProps {
  label: Translatable;
  checked: boolean;
  onChange: (isSelected: boolean) => void;
  size?: "small" | "medium";
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  isInvalid?: string | null;
  badges?: Array<{ label: string; variant: BadgeVariant }>;
  className?: string;
  icon?: IconSlug;
  describedBy?: string;
  visuallyHideLabel?: boolean;
}

export default function Checkbox({
  checked,
  onChange,
  size,
  isIndeterminate,
  isDisabled,
  isInvalid,
  badges,
  icon,
  label,
  className,
  describedBy,
  visuallyHideLabel,
}: CheckboxProps) {
  const { isFocusVisible, focusProps } = useFocusRing();
  const checkboxClass = classNames(
    styles.Checkbox,
    {
      [styles.small]: size === "small",
      [styles.focusVisible]: isFocusVisible,
      [styles.indeterminate]: isIndeterminate,
      [styles.disabled]: isDisabled,
      [styles.invalid]: isInvalid,
      [styles.checked]: checked,
    },
    className,
  );

  const handleChange = useCallback(() => onChange(!checked), [onChange, checked]);

  // These properties have to be applied via Javascript rather than HTML attributes:
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current!.indeterminate = !!isIndeterminate;
  }, [isIndeterminate]);
  useEffect(() => ref.current?.setCustomValidity(isInvalid ?? ""), [isInvalid]);

  return (
    <label className={checkboxClass}>
      <VisuallyHidden>
        <input
          checked={checked}
          onClick={handleChange}
          onChange={handleChange}
          disabled={isDisabled}
          aria-describedby={describedBy}
          {...focusProps}
          ref={ref}
        />
      </VisuallyHidden>

      <svg className={styles.SvgCheckbox} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 20 20">
        <g className={styles.box} strokeWidth="1.5">
          <rect width="20" height="20" rx="2" stroke="none" />
          <rect x=".75" y=".75" width="18.5" height="18.5" rx="1.25" fill="none" />
        </g>

        <path
          className={styles.tick}
          d="M15.313 6.501l-7.015 6.865-2.794-3.055"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path className={styles.dash} d="M5.993 10h8.436" fill="none" strokeLinecap="round" strokeWidth="2" />
      </svg>

      <span className={`${styles.Label} ${visuallyHideLabel ? "is-sr-only" : ""}`}>
        {icon ? <Icon icon={icon} inline /> : null}
        <TextElement tag="span" props={label} />
        {badges ? badges.map((badge, i) => <Badge key={i} {...badge} className={styles.Badge} />) : null}
      </span>
    </label>
  );
}
