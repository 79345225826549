import mixpanel from "mixpanel-browser";
import React, {
  MouseEvent,
  PropsWithChildren,
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AlertProvider } from "../../../../shared/hooks/useModal";
import useAnalytics from "../../analytics";
import useOrg from "../../hooks/useOrg";
import { SelectProvider } from "../design-system/RadioGroup/useSelect";
import useAnalyticsOptIn from "./AnalyticsOptInControl";
import { useLogOut } from "./ApolloProvider";
import "./AppShell.scss";
import { BillOverdue, ContractExpiryDue } from "./BillingWarnings";
import EnforceTwoFactorAuthModal from "./EnforceTwoFactorAuthModal";
import useExtraMarketingPrompt from "./ExtraMarketingPrompt";
import LoadingPage from "./LoadingPage";
import LockoutChecks from "./LockoutChecks";
import Navigation from "./Navigation";
import useNewsletterCta from "./NewsletterOptInControl";
import SkipToContent from "./SkipToContent";

function AppShell({ children }: PropsWithChildren<unknown>) {
  const { loggedInUser, isSuperAdmin } = useOrg();
  const self = { ...loggedInUser, isSuperAdmin };
  useAnalytics();

  // Toast extras:
  useExtraMarketingPrompt();
  useAnalyticsOptIn();
  useNewsletterCta();

  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(
    window.localStorage.getItem("isNavigationCollapsed") === "true",
  );

  // Theres only one nav item (Admin) that is an accordion at the moment but i doubt it'll be that way for long
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);

  const handleOnToggleNavCollapse = useCallback(() => {
    mixpanel.track("Toggled navigation collapse", { collapsed: !isNavigationCollapsed });
    setIsNavigationCollapsed(!isNavigationCollapsed);
    window.localStorage.setItem("isNavigationCollapsed", !isNavigationCollapsed ? "true" : "false");
  }, [isNavigationCollapsed]);

  const handleOnToggleAccordion = useCallback(
    (title: string) => {
      if (isNavigationCollapsed) handleOnToggleNavCollapse();
      setOpenAccordions(
        openAccordions.includes(title)
          ? openAccordions.filter((openAccordion) => openAccordion !== title)
          : openAccordions.concat([title]),
      );
    },
    [handleOnToggleNavCollapse, isNavigationCollapsed, openAccordions],
  );

  useEffect(() => {
    mixpanel.track("Loaded application", {
      collapsed: window.localStorage.getItem("isNavigationCollapsed") ?? "unset",
    });
    if (isNavigationCollapsed) {
      setOpenAccordions([]);
    }
  }, [isNavigationCollapsed]);

  const logOut = useLogOut();
  const handleOnClickLogOut = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      mixpanel.track("Log out");
      logOut();
    },
    [logOut],
  );

  return (
    <SelectProvider>
      <div className={`app-shell ${isNavigationCollapsed ? "is-nav-collapsed" : ""}`}>
        <SkipToContent />
        <AlertProvider>
          <div className="app-shell__body">
            <div className="app-shell__left-panel">
              <Navigation
                isCollapsed={isNavigationCollapsed}
                onToggleCollapse={handleOnToggleNavCollapse}
                onClickLogOut={handleOnClickLogOut}
                onToggleAccordion={handleOnToggleAccordion}
                openAccordions={openAccordions}
              />
            </div>
            <LockoutChecks>
              <Main>
                {self.org.billingWarning === "BILL_OVERDUE" && !self.org.isSuspended ? <BillOverdue /> : null}
                {self.org.billingWarning === "CONTRACT_EXPIRY_DUE" && !self.org.isSuspended ? (
                  <ContractExpiryDue />
                ) : null}
                <Suspense fallback={<LoadingPage />}>{children}</Suspense>
              </Main>
            </LockoutChecks>
          </div>
        </AlertProvider>

        {!self.isSsoUser && !self.hasMfaEnabled && !self.org.isSuspended ? <EnforceTwoFactorAuthModal /> : null}
      </div>
    </SelectProvider>
  );
}

export default AppShell;

const mainClassContext = createContext<(className: string) => void>(() => null);

function Main({ children }: PropsWithChildren<unknown>) {
  const [className, setClassName] = useState("");

  return (
    <mainClassContext.Provider value={setClassName}>
      <div className={`app-shell__content ${className}`}>
        <main className="main" id="main-content" tabIndex={-1}>
          {children}
        </main>
      </div>
    </mainClassContext.Provider>
  );
}

export function useMainClass(className: string) {
  const setClassName = useContext(mainClassContext);

  useEffect(() => {
    setClassName(className);
    return () => setClassName("");
  }, [className, setClassName]);
}
