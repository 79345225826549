import React from "react";

import errorImage from "../../../assets/images/error.svg";
import Card from "../../design-system/Card";
import Container, { ContainerSize } from "../../design-system/Container";
import EmptyPanel from "../../design-system/EmptyPanel";
import { logErrorWithId } from "../../design-system/GenericError";

export default function ErrorPage({
  errorId,
  error,
  message,
}: {
  errorId?: string;
  error?: Error | null;
  message?: string;
}) {
  if (error && !errorId) errorId = logErrorWithId(error);

  return (
    <Container size={ContainerSize.Small} className="app-shell ds-p-9">
      <Card className="ds-p-5">
        <EmptyPanel heading="Unexpected error" image={errorImage}>
          <p>{message ?? "Please try refreshing the page."}</p>
          <p>
            If this error continues, you can
            <br />
            <a href="mailto:tickets@culture-shift.co.uk">reach out to the support team here</a>
            {errorId ? `, quoting error ID ${errorId}.` : "."}
          </p>
        </EmptyPanel>
      </Card>
    </Container>
  );
}
