import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import notFoundImage from "../../assets/images/not-found.svg";
import Card from "../design-system/Card";
import Container, { ContainerSize } from "../design-system/Container";
import EmptyPanel from "../design-system/EmptyPanel";

const NotFoundPage: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Culture Shift | Not Found";
    mixpanel.track("Visited Not Found page");
  }, []);

  return (
    <Container size={ContainerSize.Small} className="ds-my-9">
      <NotFoundMessage />
    </Container>
  );
};

export const NotFoundMessage = () => (
  <Card className="ds-p-5">
    <EmptyPanel heading="Page not found" image={notFoundImage}>
      <p>
        The content you were looking for could not be found.
        <br />
        Please try <RouterLink to="/">returning to the homepage</RouterLink>.
      </p>
    </EmptyPanel>
  </Card>
);

export default NotFoundPage;
