import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND } from "lexical";
import React from "react";

import InsertFileUploadButton from "../blocks/FileUploadBlock/InsertFileUploadButton";
import InsertHtmlSnippetButton from "../blocks/HtmlSnippetBlock/InsertHtmlSnippetButton";
import InsertImageButton from "../blocks/ImageBlock/InsertImageButton";
import InsertChartButton from "../blocks/chart/InsertChartButton";
import InsertTableButton from "../blocks/table/InsertTableButton";
import CommandButton from "./CommandButton";
import FormattingButton from "./FormattingButton";
import LinkButton from "./LinkButton";
import StylingDropdown from "./StylingDropdown";
import styles from "./styles.module.scss";

export default function BlockEditorToolbar({
  hideInsightsControls,
}: {
  /** Disable charts and tables based on Analytics */
  hideInsightsControls: boolean;
}) {
  return (
    <div className={styles.Toolbar}>
      <div className={styles.Group}>
        <CommandButton
          command={UNDO_COMMAND}
          canCommand={CAN_UNDO_COMMAND}
          payload={undefined}
          icon="undo"
          label="Undo"
        />
        <CommandButton
          command={REDO_COMMAND}
          canCommand={CAN_REDO_COMMAND}
          payload={undefined}
          icon="redo"
          label="Redo"
        />
      </div>

      <div className={styles.Group}>
        <StylingDropdown />
      </div>

      <div className={styles.Group}>
        <FormattingButton format="bold" icon="bold" label="Bold" />
        <FormattingButton format="italic" icon="italic" label="Italic" />
        <FormattingButton format="underline" icon="underline" label="Underline" />
        <LinkButton />
      </div>

      <div className={styles.Group}>
        <InsertImageButton />
        <InsertFileUploadButton />
        <InsertTableButton />
        {hideInsightsControls ? null : <InsertChartButton />}
        <InsertHtmlSnippetButton />
      </div>
    </div>
  );
}
