import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalEditor } from "lexical";
import React, { FormEvent, useCallback, useState } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import useModal, { PropsWithAlertResolver } from "../../../../../../../shared/hooks/useModal";
import IconButton from "../../../Button/IconButton";
import { ContainerSize } from "../../../Container";
import { useIsRootEditor } from "../../hooks";
import HtmlInput from "./HtmlInput";
import { INSERT_HTML_SNIPPET_COMMAND } from "./HtmlSnippetPlugin";

export default function InsertHtmlSnippetButton() {
  const showModal = useModal(InsertModal, { size: ContainerSize.Medium });
  const isDisabled = !useIsRootEditor();
  const [editor] = useLexicalComposerContext();
  const open = useCallback(() => showModal({ editor }), [editor, showModal]);

  return (
    <IconButton
      size="small"
      variant="ghost"
      onClick={open}
      icon="browser"
      label="Insert an HTML snippet, such as a video embed"
      disabled={isDisabled}
    />
  );
}

function InsertModal({ editor, resolve, cancel }: PropsWithAlertResolver<void, { editor: LexicalEditor }>) {
  const [src, setSrc] = useState("");
  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      editor.dispatchCommand(INSERT_HTML_SNIPPET_COMMAND, { src, version: 1, type: "html-snippet", indent: 0 });
      resolve();
    },
    [editor, resolve, src],
  );
  return (
    <form onSubmit={onSubmit}>
      <SectionHeader title="Edit HTML snippet" />
      <div>
        <HtmlInput value={src} label="HTML snippet" onChange={setSrc} />
      </div>
      <SectionFooter>
        <Button variant="ghost" onClick={cancel} label="Cancel" />
        <Button submit label="OK" />
      </SectionFooter>
    </form>
  );
}
