import React, { FunctionComponent, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import ModalProvider from "../../shared/components/design-system/Modal/RootProvider";
import AppShell from "./components/global-furniture/AppShell";
import NotFoundPage from "./components/global-furniture/NotFoundPage";
import HelpPage from "./components/help";
import ReportFilterProvider from "./components/reports/ReportPage/ReportFilterProvider";
import InsightsPage from "./components/sites-management/InsightsPage";
import { SiteDataRoute } from "./components/sites-management/useSiteData";
import { OrgDataRoute } from "./hooks/useOrgData";
import "./translations";

const AdminPage = lazy(() => import("./components/admin/AdminPage"));
const OutcomesPage = lazy(() => import("./components/admin/OutcomesPage"));
const OptionsPage = lazy(() => import("./components/admin/OptionsPage"));
const ExportsPage = lazy(() => import("./components/admin/ExportsPage"));
const AuditLogPage = lazy(() => import("./components/admin/AuditLog"));
const NameMatchingPage = lazy(() => import("./components/admin/NameMatchingPage"));
const AnalyticsPage = lazy(() => import("./components/analytics/AnalyticsPageContainer"));
const IndexPage = lazy(() => import("./components/global-furniture/RootPage"));
const CreateOrganisationPage = lazy(() => import("./components/organisations/CreateOrganisationPage"));
const FormOverviewPage = lazy(() => import("./components/organisations/FormOverviewPage"));
const FormPage = lazy(() => import("./components/organisations/FormPage"));
const OrganisationSettingsPage = lazy(
  () => import("./components/organisations/OrganisationPage/OrganisationSettingsPage"),
);
const OrganisationSitesPage = lazy(() => import("./components/organisations/OrganisationPage/OrganisationSitesPage"));
const OrganisationQuestionsPage = lazy(
  () => import("./components/organisations/OrganisationPage/OrganisationQuestionsPage"),
);
const OrganisationOutcomesPage = lazy(
  () => import("./components/organisations/OrganisationPage/OrganisationOutcomesPage"),
);
const OrganisationSurveysPage = lazy(
  () => import("./components/organisations/OrganisationPage/OrganisationSurveysPage"),
);
const OrganisationPage = lazy(() => import("./components/organisations/OrganisationPage"));
const OrganisationUsersPage = lazy(() => import("./components/organisations/OrganisationPage/OrganisationUsersPage"));
const OrganisationAuditPage = lazy(() => import("./components/organisations/OrganisationPage/OrganisationAuditPage"));
const OrganisationsPage = lazy(() => import("./components/organisations/OrganisationsPage"));
const ResourcesPage = lazy(() => import("./components/resources/ResourcesPage"));
const QuestionPage = lazy(() => import("./components/organisations/QuestionPage"));
const RiskAssessmentsPage = lazy(() => import("./components/reports/RiskAssessmentsPage"));
const ReportPage = lazy(() => import("./components/reports/ReportPage"));
const ReportsPage = lazy(() => import("./components/reports/ReportsPage"));
const SearchReportsPage = lazy(() => import("./components/reports/SearchReportsPage"));
const ArticlePage = lazy(() => import("./components/sites-management/ArticlePage"));
const CampaignPage = lazy(() => import("./components/sites-management/CampaignPage"));
const PagePage = lazy(() => import("./components/sites-management/PagePage"));
const SimpleFormEditorPage = lazy(() => import("./components/sites-management/SimpleFormEditorPage"));
const SitePage = lazy(() => import("./components/sites-management/SitePage"));
const SiteSettingsPage = lazy(() => import("./components/sites-management/SiteSettingsPage"));
const SurveyPage = lazy(() => import("./components/surveys/SurveyPage"));
const SurveyAnalyticsPage = lazy(() => import("./components/surveys/SurveyAnalyticsPage"));
const SurveysPage = lazy(() => import("./components/surveys/SurveysPage"));
const CreateSurveyPage = lazy(() => import("./components/surveys/CreateSurveyPage"));
const OptionEditorPage = lazy(() => import("./components/admin/OptionEditorPage"));
const SitesPage = lazy(() => import("./components/sites-management/SitesPage"));
const TeamPage = lazy(() => import("./components/users/TeamPage"));
const UserPage = lazy(() => import("./components/users/UserPage"));
const UsersPage = lazy(() => import("./components/users/UsersPage"));
const GraphqlPlayground = lazy(() => import("./components/global-furniture/GraphqlPlayground"));
const AccountPage = lazy(() => import("./components/account/AccountPage"));

const AppRouter: FunctionComponent = () =>
  useRoutes([
    { path: "/", element: <IndexPage /> },
    {
      path: "/account",
      element: (
        <OrgDataRoute>
          <AccountPage />
        </OrgDataRoute>
      ),
    },

    {
      path: "/reports",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <ReportsPage /> },
        { path: "unassigned", element: <ReportsPage status="unassigned" /> },
        { path: "assigned", element: <ReportsPage status="assigned" /> },
        { path: "accepted", element: <ReportsPage status="accepted" /> },
        { path: "closed", element: <ReportsPage status="closed" /> },
        { path: "archived", element: <ReportsPage status="archived" /> },
        { path: "all", element: <ReportsPage status="all" /> },
        { path: "search", element: <SearchReportsPage /> },
        {
          path: ":id",
          element: <ReportFilterProvider />,
          children: [
            { path: "", element: <ReportPage /> },
            { path: ":risk-assessments", element: <RiskAssessmentsPage /> },
            { path: ":risk-assessments/:riskAssessmentId", element: <RiskAssessmentsPage /> },
            { path: ":tab", element: <ReportPage /> },
          ],
        },
      ],
    },

    {
      path: "/analytics",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <Navigate replace to="/analytics/at-a-glance" /> },
        { path: ":tab", element: <AnalyticsPage /> },
      ],
    },

    {
      path: "/surveys",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <SurveysPage /> },
        { path: "create", element: <CreateSurveyPage /> },
        { path: ":id", element: <SurveyPage /> },
        { path: ":id/analytics", element: <SurveyAnalyticsPage /> },
      ],
    },

    {
      path: "/users",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <UsersPage /> },
        { path: ":tab", element: <UsersPage /> },
        { path: "create", element: <UserPage /> },
        { path: "users/:id", element: <UserPage /> },
        { path: "teams/create", element: <TeamPage /> },
        { path: "teams/:id", element: <TeamPage /> },
      ],
    },
    { path: "/sites", element: <SitesPage /> },
    {
      path: "/sites/:siteId",
      element: (
        <OrgDataRoute>
          <SiteDataRoute />
        </OrgDataRoute>
      ),
      children: [
        { path: "", element: <SitePage /> },
        { path: "settings", element: <SiteSettingsPage /> },
        { path: ":tab", element: <SitePage /> },
        { path: "categories/:categoryId/new-article", element: <ArticlePage /> },
        { path: "articles/:articleId", element: <ArticlePage /> },
        { path: "campaigns/create", element: <CampaignPage /> },
        { path: "campaigns/:campaignId", element: <CampaignPage /> },
        { path: "pages/create", element: <PagePage /> },
        { path: "pages/:pageId", element: <PagePage /> },
        { path: "forms/:id", element: <SimpleFormEditorPage /> },
        { path: "insights/create", element: <InsightsPage /> },
        { path: "insights/:documentId", element: <InsightsPage /> },
      ],
    },

    { path: "/organisations", element: <OrganisationsPage /> },
    { path: "/organisations/create", element: <CreateOrganisationPage /> },
    {
      path: "/organisations/:organisationId",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <OrganisationPage /> },
        { path: "settings", element: <OrganisationSettingsPage /> },
        { path: "sites", element: <OrganisationSitesPage /> },
        { path: "questions", element: <OrganisationQuestionsPage /> },
        { path: "outcomes", element: <OrganisationOutcomesPage /> },
        { path: "surveys", element: <OrganisationSurveysPage /> },
        { path: "audit", element: <OrganisationAuditPage /> },
        { path: "users", element: <OrganisationUsersPage /> },
        { path: "users/create", element: <UserPage /> },
        { path: "users/users/:id", element: <UserPage /> },
        { path: "users/teams/:id", element: <TeamPage /> },
        {
          path: "sites/:siteId",
          element: <SiteDataRoute />,
          children: [
            { path: "", element: <SitePage /> },
            { path: "settings", element: <SiteSettingsPage /> },
            { path: ":tab", element: <SitePage /> },
            { path: "categories/:categoryId/new-article", element: <ArticlePage /> },
            { path: "articles/:articleId", element: <ArticlePage /> },
            { path: "campaigns/create", element: <CampaignPage /> },
            { path: "campaigns/:campaignId", element: <CampaignPage /> },
            { path: "pages/create", element: <PagePage /> },
            { path: "pages/:pageId", element: <PagePage /> },
            { path: "insights/create", element: <InsightsPage /> },
            { path: "insights/:documentId", element: <InsightsPage /> },
            { path: "forms/create", element: <FormPage /> },
            { path: "forms/:formId", element: <FormPage /> },
            { path: "forms/:formId/overview", element: <FormOverviewPage /> },
          ],
        },
        { path: "questions/create", element: <QuestionPage /> },
        { path: "questions/:questionId", element: <QuestionPage /> },
      ],
    },

    {
      path: "/admin",
      element: <OrgDataRoute />,
      children: [
        { path: "", element: <AdminPage /> },
        { path: "options/:questionId", element: <OptionEditorPage /> },
        { path: "outcomes", element: <OutcomesPage /> },
        { path: "options", element: <OptionsPage /> },
        { path: "name-matching", element: <NameMatchingPage /> },
        { path: "export", element: <ExportsPage /> },
        { path: "audit", element: <AuditLogPage /> },
      ],
    },

    { path: "/resources", element: <ResourcesPage /> },

    { path: "/help", element: <HelpPage /> },

    { path: "/playground", element: <GraphqlPlayground /> },

    { path: "*", element: <NotFoundPage /> },
  ]);

export default function App() {
  return (
    <ModalProvider>
      <AppShell>
        <AppRouter />
      </AppShell>
    </ModalProvider>
  );
}
