import React, { MouseEvent, PropsWithChildren } from "react";

import { Translatable, Translation } from "../../translation";
import { LinkClassProps, linkClassName } from "./styles";

// This is a regular button which visually resembles a link (but is not simply a link with an onClick handler for accessibility reasons). It's used for low-prominence actions. If you're looking for a link that's styled like a button, that's LinkButton.

export default function ButtonLink({
  onClick,
  children,
  label,
  ...props
}: LinkClassProps & {
  onClick?: (ev: MouseEvent<HTMLElement>) => void;
} & ({ label: Translatable; children?: undefined } | PropsWithChildren<{ label?: undefined }>)) {
  return (
    <button className={linkClassName(props)} type="button" onClick={onClick}>
      {children ?? <Translation props={label} />}
    </button>
  );
}
