import React, { useCallback } from "react";

import { PublicationMode } from "../../../../../../__generated__/graphql";
import Alert from "../../../../../../shared/components/design-system/Alert";
import Dialog from "../../../../../../shared/components/design-system/Dialog";
import { ReadOnlyStringInput } from "../../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useConfirm } from "../../../../hooks/useAlert";
import useAsyncFunctionWithLoading, {
  useAsyncCallbackWithLoading,
} from "../../../../hooks/useAsyncFunctionWithLoading";
import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import Button from "../../../design-system/Button";
import IconButton from "../../../design-system/Button/IconButton";
import CopyButton from "../../../design-system/Button/specifics/CopyButton";
import useSiteData from "../../useSiteData";
import { ToastKey, usePersistence } from "../persistence";
import styles from "./styles.module.scss";

export default function PrivateLinkButton() {
  const { savedDocument: doc } = usePersistence();
  const { isOpen, open, close } = useOpenableWithTracking("Private link modal");

  if (!doc) return null;
  const { published } = doc;
  if (published?.publicationMode === PublicationMode.Public) return null;

  return (
    <>
      <Button variant="action" onClick={open} icon="link">
        Private link
      </Button>
      <PrivateLinkDialog isOpen={isOpen} close={close} />
    </>
  );
}

function PrivateLinkDialog({ isOpen, close }: { isOpen: boolean; close: () => void }) {
  const site = useSiteData();
  const confirm = useConfirm();

  const { loading, isDirty, publish, unpublish, savedDocument: doc } = usePersistence();

  const [publishWithPrivateLink, publishing] = useAsyncCallbackWithLoading(
    (toastKey?: ToastKey) => publish(PublicationMode.PrivateLink, toastKey),
    [publish],
  );

  const refresh = useCallback(async () => {
    if (
      await confirm({
        title: "Reset link",
        okCaption: "Reset link",
        okVariant: "primary",
        cancelCaption: "Cancel",
        children:
          "Are you sure you want to create a new URL for this private share link? This will make the old link invalid.",
      })
    ) {
      await publishWithPrivateLink("privateLinkRefreshed");
    }
  }, [confirm, publishWithPrivateLink]);

  const [wrappedDelete, deleting] = useAsyncFunctionWithLoading(unpublish);

  const deleteLink = useCallback(async () => {
    if (
      await confirm({
        title: "Delete private link",
        okCaption: "Delete link",
        okVariant: "danger",
        cancelCaption: "Cancel",
        children: "Are you sure you want to delete the private link? This cannot be undone.",
      })
    ) {
      await wrappedDelete("privateLinkDeleted");
      close();
    }
  }, [confirm, wrappedDelete, close]);

  // This dialog cannot be opened until doc exists
  if (!doc) return null;
  const { published } = doc;

  if (!published) {
    return (
      <Dialog isOpen={isOpen} onClose={close}>
        <SectionHeader title="Private link" />
        <p>This will create a shareable URL. Anyone with the URL will be able to see the document.</p>
        {isDirty ? <p>You need to save your changes to create a private link.</p> : null}
        <SectionFooter>
          <Button variant="ghost" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={publishWithPrivateLink} loading={publishing} disabled={loading}>
            {isDirty ? "Save and create" : "Create private link"}
          </Button>
        </SectionFooter>
      </Dialog>
    );
  }

  const url = `https://${site.urls[0].host}${published.path}`;

  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <SectionHeader title="Private link" />
      <Alert variant="info">Anyone with the link can see the document</Alert>
      <div className={`${styles.PrivateLinkDisplay} can-load ${loading ? "is-loading" : ""}`}>
        <ReadOnlyStringInput
          label="Private link to share"
          value={url}
          className={styles.PrivateLinkTextBox}
          size="medium"
          visuallyEnabled
        />
        <CopyButton tracker="Copied private link" text={url} iconButton size="small" />
        <IconButton
          onClick={refresh}
          label="Reset link"
          icon="undo"
          loading={publishing}
          disabled={loading}
          size="small"
        />
      </div>
      <SectionFooter>
        <Button variant="dangerTertiary" onClick={deleteLink} loading={deleting} disabled={loading}>
          Delete link
        </Button>
        <Button variant="primary" onClick={close} className="ds-ml-auto">
          Done
        </Button>
      </SectionFooter>
    </Dialog>
  );
}
