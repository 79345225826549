import { useMutation } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { UploadImageMutation, UploadImageMutationVariables } from "../../../../../../../__generated__/graphql";
import { uploadFile } from "../../../../../../../shared/upload/uploadFile";
import { UPLOAD_IMAGE } from "../../../../../graphql/mutations";

export default function useUploadInput(
  siteId: string,
  mixpanelName: string,
  src?: string,
  onComplete?: (file: File) => Promise<void>,
) {
  const [isUploading, setImageUploading] = useState<boolean | "ERROR">(false);
  const [url, setURL] = useState<string>(src ?? "");
  const [uploadImage] = useMutation<UploadImageMutation, UploadImageMutationVariables>(UPLOAD_IMAGE, {
    variables: { siteId },
  });

  const selectFile = useCallback(
    async ([file]: File[]) => {
      setImageUploading(true);
      mixpanel.track(mixpanelName);
      try {
        setImageUploading(true);
        const uploadUrlsResult = await uploadImage();
        const {
          uploadImage: { publicUrl, uploadUrl },
        } = uploadUrlsResult.data!;
        await uploadFile(file, uploadUrl);
        setImageUploading(false);
        setURL(publicUrl);
        await onComplete?.(file);
      } catch (e) {
        setImageUploading("ERROR");
      }
    },
    [mixpanelName, onComplete, uploadImage],
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: selectFile,
  });

  return {
    url,
    isUploading,
    getRootProps,
    getInputProps,
  };
}
