import React from "react";

import { InDetailData } from "../../../../../../shared/components/charts/types";
import Dialog from "../../../../../../shared/components/design-system/Dialog/index";
import StringInput from "../../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import Button from "../../Button";
import { ContainerSize } from "../../Container";
import { useEditLabel } from "./useEditLabels";

interface EditLabelProps {
  isOpen: boolean;
  closeModal: () => void;
  saveLabels: (value: InDetailData) => void;
  graphData: InDetailData;
}
export default function EditLabels({ isOpen, closeModal, saveLabels, graphData }: EditLabelProps) {
  const handleSaveLabels = () => {
    saveLabels(labelsData);
    closeModal();
  };

  const [labelsData, onLabelInputChange, onSplitInputChange] = useEditLabel(graphData);

  return (
    <Dialog isOpen onClose={closeModal} size={ContainerSize.Medium}>
      <SectionHeader title="Edit labels" />
      <form>
        {labelsData !== null ? (
          <>
            {labelsData.categories.map((label, i) => (
              <StringInput
                key={i}
                size="medium"
                label={graphData.categories[i].label}
                value={label.label}
                onChange={(value) => {
                  onLabelInputChange(i, value);
                }}
              />
            ))}
            {graphData.splits !== undefined && labelsData.splits ? (
              <>
                {labelsData.splits.map((label, i) => (
                  <StringInput
                    key={i}
                    size="medium"
                    label={graphData.splits![i].label}
                    value={label.label}
                    onChange={(value) => {
                      onSplitInputChange(i, value);
                    }}
                  />
                ))}
              </>
            ) : null}
          </>
        ) : null}
      </form>
      <SectionFooter>
        <Button onClick={closeModal} variant="ghost" className="ds-ml-auto">
          Cancel
        </Button>
        <Button onClick={handleSaveLabels}>Save Labels</Button>
      </SectionFooter>
    </Dialog>
  );
}
