import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect } from "react";

import { FileUpload } from "../../../../../../../shared/block-editor-data/types";
import $insertBlockNode from "../block-nodes/insert-block-node";
import { $createFileUploadNode, FileUploadNode } from "./FileUploadNode";

export type InsertFileUploadPayload = Readonly<FileUpload>;

export const INSERT_FILE_COMMAND: LexicalCommand<InsertFileUploadPayload> = createCommand();

export default function FileUploadPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([FileUploadNode])) {
      throw new Error("FileUploadPlugin: FileUploadNode not registered on editor");
    }
    return mergeRegister(
      editor.registerCommand<InsertFileUploadPayload>(
        INSERT_FILE_COMMAND,
        (payload) => {
          $insertBlockNode($createFileUploadNode(payload));
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [editor]);

  return null;
}
