import React, { MouseEvent, PropsWithChildren, useCallback, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon, { IconSlug } from "../../../../../shared/components/design-system/Icon";
import Tooltip from "../../design-system/Tooltip";
import "./styles.scss";

export default function NavigationAccordion({
  title,
  icon,
  toggleAccordion,
  openAccordions,
  subPages = [], // Other 'folders' that cause this tab to highlight as active
  isNew = false,
  isNavCollapsed,
  children,
}: PropsWithChildren<{
  subPages?: string[];
  title: string;
  icon: IconSlug;
  toggleAccordion: (title: string) => void;
  isNew?: boolean;
  isNavCollapsed: boolean;
  openAccordions: string[];
}>) {
  const navItemRef = useRef<HTMLLIElement>(null);

  const { pathname } = useLocation();
  const isActive = [...subPages].some((url) => pathname === url || pathname.startsWith(`${url}/`));
  const isAccordionExpanded = openAccordions.includes(title);

  return (
    <li ref={navItemRef} className="c-navigation__item">
      <button
        onClick={() => toggleAccordion(title)}
        className={`c-navigation__link ${isActive ? "is-active" : ""} ${isAccordionExpanded ? "is-accordion" : ""}`}
        aria-expanded={isAccordionExpanded ? true : false}
      >
        <span className="c-navigation__link-icon" aria-hidden="true">
          <Icon icon={icon} />
        </span>
        <span className="c-navigation__link-label">{title}</span>
        <Icon className="ds-ml-auto" icon={isAccordionExpanded ? "chevronUp" : "chevronDown"} />
      </button>
      <div className={isAccordionExpanded ? "accordion-open" : "accordion-closed"}>
        <ul>{children}</ul>
      </div>
      {isNavCollapsed ? <Tooltip anchorRef={navItemRef}>{title}</Tooltip> : null}
    </li>
  );
}

export function NavigationSubMenuItem({
  to,
  title,
  closeMobileNavigation,
  onClick,
  isNavCollapsed,
  neverActive,
  subPages = [],
}: {
  to: string;
  title: string;
  closeMobileNavigation: () => void;
  onClick?: (event: MouseEvent) => void;
  isNavCollapsed: boolean;
  neverActive?: boolean;
  subPages?: string[];
}) {
  const navItemRef = useRef<HTMLLIElement>(null);
  const isInternal = to.startsWith("/");

  const handleNavLinkClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      onClick?.(event);
      closeMobileNavigation();
      if (isInternal) {
        (event.target as HTMLAnchorElement).blur();
      }
    },
    [isInternal, onClick, closeMobileNavigation],
  );
  const { pathname } = useLocation();
  const isActive =
    !neverActive && isInternal && [to, ...subPages].some((url) => pathname === url || pathname.startsWith(`${url}/`));
  return (
    <li ref={navItemRef} className="c-navigation__sub-item">
      <NavLink
        onClick={handleNavLinkClick}
        className={`c-navigation__link ${isActive ? "is-active" : ""}`}
        to={`${to}#main-content`}
        target={isInternal ? undefined : "_blank"}
      >
        <Icon icon={null} />
        <span className="c-navigation__link-label">{title}</span>
      </NavLink>
    </li>
  );
}
