import mixpanel from "mixpanel-browser";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import ReactCrop, { Crop } from "react-image-crop";

import AlertDangerIcon from "../../../../../shared/components/design-system/Alert/icons/AlertDangerIcon";
import Button from "../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import { ActionGroup } from "../../../../../shared/components/design-system/component-groups/ActionGroup";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { Transform, applyTransformations } from "./imageUtils";

const CROPPER_MAX_HEIGHT = 542;

const Cropper: FunctionComponent<{
  sourceImage?: HTMLImageElement;
  isError: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onSave: () => void;
  onCropChange: Dispatch<SetStateAction<Crop>>;
  onTransformChange: Dispatch<SetStateAction<Transform>>;
  crop: Crop;
  transform: Transform;
  recommendedWidth?: number;
  recommendedHeight?: number;
}> = ({
  sourceImage,
  isError,
  onCancel,
  onDelete,
  onSave,
  onCropChange,
  onTransformChange,
  crop,
  transform,
  recommendedWidth,
  recommendedHeight,
}) => {
  const [initialCropDone, setInitialCropDone] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  const previewImageUrl: string | undefined = useMemo(
    () => (sourceImage ? applyTransformations(sourceImage, transform).toDataURL("image/jpeg", 1) : undefined),
    [sourceImage, transform],
  );

  const imageLoaded = useCallback(
    (ev: SyntheticEvent<HTMLImageElement>) => {
      const img = ev.currentTarget;
      setImageLoading(false);
      if (!initialCropDone) {
        setInitialCropDone(true);
        if (recommendedWidth && recommendedHeight) {
          const aspect = recommendedWidth / recommendedHeight;
          const cropWidth = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
          const cropHeight = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;

          onCropChange({
            unit: "%",
            width: cropWidth,
            height: cropHeight,
            x: (100 - cropWidth) / 2,
            y: (100 - cropHeight) / 2,
          });
        } else {
          onCropChange({ unit: "%", width: 100, height: 100, x: 0, y: 0 });
        }

        return false;
      }
    },
    [initialCropDone, onCropChange, recommendedHeight, recommendedWidth],
  );

  const cropChange = useCallback(
    (_: Crop, crop: Crop) => {
      onCropChange(crop);
    },
    [onCropChange],
  );

  const rotateCounterClockwise = useCallback(() => {
    setImageLoading(true);
    onCropChange((crop) => ({
      ...crop,
      width: crop.height,
      height: crop.width,
      x: crop.y,
      y: 100 - crop.x - crop.width,
    }));
    onTransformChange((transformParams) => ({ ...transformParams, rotate: (360 + transformParams.rotate - 90) % 360 }));
    mixpanel.track("Image edited", { operation: "rotate counter-clockwise" });
  }, [onCropChange, onTransformChange]);

  const rotateClockwise = useCallback(() => {
    setImageLoading(true);
    onCropChange((crop) => ({
      ...crop,
      width: crop.height,
      height: crop.width,
      x: 100 - crop.y - crop.height,
      y: crop.x,
    }));
    onTransformChange((transformParams) => ({ ...transformParams, rotate: (transformParams.rotate + 90) % 360 }));
    mixpanel.track("Image edited", { operation: "rotate clockwise" });
  }, [onCropChange, onTransformChange]);

  const flipV = useCallback(() => {
    setImageLoading(true);
    onCropChange((crop) => ({ ...crop, y: (crop.y > 50 ? 50 - (crop.y - 50) : 50 + (50 - crop.y)) - crop.height }));
    onTransformChange((transformParams) =>
      transformParams.rotate === 90 || transformParams.rotate === 270
        ? { ...transformParams, flipH: !transformParams.flipH }
        : { ...transformParams, flipV: !transformParams.flipV },
    );
    mixpanel.track("Image edited", { operation: "flip vertically" });
  }, [onCropChange, onTransformChange]);

  const flipH = useCallback(() => {
    setImageLoading(true);
    onCropChange((crop) => ({ ...crop, x: (crop.x > 50 ? 50 - (crop.x - 50) : 50 + (50 - crop.x)) - crop.width }));
    onTransformChange((transformParams) =>
      transformParams.rotate === 90 || transformParams.rotate === 270
        ? { ...transformParams, flipV: !transformParams.flipV }
        : { ...transformParams, flipH: !transformParams.flipH },
    );
    mixpanel.track("Image edited", { operation: "flip horizontally" });
  }, [onCropChange, onTransformChange]);

  const isLowResolution =
    recommendedWidth && sourceImage ? sourceImage.width * ((crop.width ?? 100) / 100) < recommendedWidth : false;
  const isRotated = transform.rotate === 90 || transform.rotate === 270;
  const isPortrait =
    sourceImage && (isRotated ? sourceImage.width > sourceImage.height : sourceImage.height > sourceImage.width);

  return (
    <Dialog isOpen onClose={onCancel}>
      <SectionHeader title="Image editor" />
      <p className="c-image-uploader-size">
        {isError ? (
          <>
            <span className="c-image-uploader-size__warning-icon">
              <AlertDangerIcon />
            </span>
            <span className="c-image-uploader-size__warning-text">
              Invalid image format selected - please select a new image
            </span>
          </>
        ) : isLowResolution ? (
          <>
            <span className="c-image-uploader-size__warning-icon">
              <AlertDangerIcon />
            </span>
            <span className="c-image-uploader-size__warning-text">Image is below recommended size</span>
          </>
        ) : recommendedWidth && recommendedHeight ? (
          <>
            Recommended image size: {recommendedWidth} x {recommendedHeight} px
          </>
        ) : (
          " "
        )}
      </p>
      <div
        className="c-image-uploader-cropper"
        style={
          isPortrait
            ? isRotated
              ? { width: `${(CROPPER_MAX_HEIGHT * sourceImage!.height) / sourceImage!.width}px` }
              : { width: `${(CROPPER_MAX_HEIGHT * sourceImage!.width) / sourceImage!.height}px` }
            : {}
        }
      >
        {previewImageUrl ? (
          <ReactCrop
            crop={crop}
            aspect={recommendedWidth && recommendedHeight ? recommendedWidth / recommendedHeight : undefined}
            onChange={cropChange}
            keepSelection
          >
            <img src={previewImageUrl} alt="" onLoad={imageLoaded} />
          </ReactCrop>
        ) : !isError ? (
          <LoadingSpinner />
        ) : null}
      </div>
      <SectionFooter>
        {isError ? null : (
          <>
            <div className="c-image-uploader-cropper__actions">
              <ActionGroup>
                <Button
                  variant="action"
                  onClick={rotateCounterClockwise}
                  disabled={imageLoading}
                  icon="rotateAnticlockwise"
                  iconButton
                  label="Rotate counter-clockwise"
                />
                <Button
                  variant="action"
                  onClick={rotateClockwise}
                  disabled={imageLoading}
                  icon="rotateClockwise"
                  iconButton
                  label="Rotate clockwise"
                />
                <Button
                  variant="action"
                  onClick={flipH}
                  disabled={imageLoading}
                  icon="flipHorizontal"
                  iconButton
                  label="Flip horizontally"
                />
                <Button
                  variant="action"
                  onClick={flipV}
                  disabled={imageLoading}
                  icon="flipVertical"
                  iconButton
                  label="Flip vertically"
                />
              </ActionGroup>
            </div>
            <Button variant="ghost" onClick={onDelete} label="Delete" />
            <Button variant="primary" onClick={onSave} label="Save" />
          </>
        )}
      </SectionFooter>
    </Dialog>
  );
};

export default Cropper;
