import * as Sentry from "@sentry/browser";
import type {
  DOMConversion,
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

import { FileUpload } from "../../../../../../../shared/block-editor-data/types";

const FileUploadComponent = React.lazy(
  // @ts-ignore
  () => import("./FileUploadComponent"),
);

function convertFigureElement(node: HTMLElement): null | DOMConversion {
  if (node.tagName !== "FIGURE") return null;
  if (!node.classList.contains("attachment--file")) return null;

  try {
    const details = JSON.parse(node.getAttribute("data-trix-attachment")!);
    if (typeof details.href !== "string") return null;

    return {
      priority: 1,
      conversion: (node) => ({
        node: $createFileUploadNode({
          src: details.href,
          fileName: details.filename,
          type: "file-upload",
          version: 1,
          indent: 0,
          fileSize: details.filesize,
          mimeType: details.contentType,
        }),
      }),
    };
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export type SerializedFileUploadNode = Spread<FileUpload, SerializedLexicalNode>;

export class FileUploadNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __fileName: string;
  __fileSize: number;
  __mimeType: string;

  static getType(): string {
    return "file-upload";
  }

  static clone(node: FileUploadNode): FileUploadNode {
    return new FileUploadNode(node.__src, node.__fileName, node.__fileSize, node.__mimeType);
  }

  static importJSON(serializedNode: SerializedFileUploadNode): FileUploadNode {
    const { fileName, src, mimeType, fileSize } = serializedNode;

    const node = $createFileUploadNode({
      fileName,
      src,
      type: "file-upload",
      version: 1,
      indent: 0,
      mimeType,
      fileSize,
    });

    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("a");
    element.setAttribute("href", this.__src);
    element.innerText = this.__fileName;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      figure: convertFigureElement,
    };
  }

  constructor(src: string, fileName: string, fileSize: number, mimeType: string, key?: NodeKey) {
    super(key);
    this.__src = src;
    this.__fileName = fileName;
    this.__fileSize = fileSize;
    this.__mimeType = mimeType;
  }

  exportJSON(): SerializedFileUploadNode {
    return {
      fileName: this.getFileName(),
      fileSize: this.getFileSize(),
      mimeType: this.getMimeType(),
      src: this.getSrc(),
      type: "file-upload",
      version: 1,
      indent: 0,
    };
  }

  // View

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.fileUpload;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateFileUploadNode(src: string, fileName: string, fileSize: number, mimeType: string, key?: NodeKey): void {
    const self = this.getWritable();
    self.__src = src;
    self.__fileName = fileName;
    self.__fileSize = fileSize;
    self.__mimeType = mimeType;
  }

  updateDOM(): false {
    return false;
  }

  getFileName(): string {
    return this.__fileName;
  }

  getFileSize(): number {
    return this.__fileSize;
  }

  getSrc(): string {
    return this.__src;
  }

  getMimeType(): string {
    return this.__mimeType;
  }

  isInline() {
    return false;
  }

  decorate(_: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense>
        <FileUploadComponent
          src={this.__src}
          fileName={this.__fileName}
          fileSize={this.__fileSize}
          mimeType={this.__mimeType}
          nodeKey={this.getKey()}
          theme={config.theme}
        />
      </Suspense>
    );
  }
}

export function $createFileUploadNode({ fileName, src, fileSize, mimeType }: FileUpload): FileUploadNode {
  return new FileUploadNode(src, fileName, fileSize, mimeType);
}

export function $isFileUploadNode(node: LexicalNode | null | undefined): node is FileUploadNode {
  return node instanceof FileUploadNode;
}
