import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import React, { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  FormCategory,
  GetReportIdsQuery,
  GetReportIdsQueryVariables,
  OrderBy,
  ReportSearch,
} from "../../../../../__generated__/graphql";
import { Breadcrumb } from "../../design-system/PageHeader/CombinedHeader";

const context = createContext<FilterContext>({
  search: "",
  tab: "all",
  reportIdList: null,
  reportIdListLoading: false,
});

interface FilterContext {
  search: string;
  tab: string;
  reportIdList: string[] | null;
  reportIdListLoading: boolean;
}

/** This is a component that wraps the whole /report/:id route,
and remembers what filter you had on the reports page (if any)
so that you can render the right breadcrumbs */
export default function ReportFilterProvider({ children }: PropsWithChildren<unknown>) {
  const { state } = useLocation();

  // If we navigate around within the report page, we don't want to worry about forwarding the state every time, so just remember it until we leave the "report view" area. This should work when switching to risk assessments or linked reports.
  const {
    // These two are for displaying the breadcrumb:
    search,
    tab,
    // These two are for fetching the report list:
    filter,
    order,
  } = useMemo(() => {
    if (!state) return { search: "", tab: "all", filter: null, order: null };
    const { search, tab, filter, order } = state as Record<string, unknown>;
    if (!search && !tab) return { search: "", tab: "all", filter: null, order: null };
    return {
      search: search?.toString() ?? "",
      tab: tab?.toString() ?? "tab",
      filter: filter as ReportSearch,
      order: order as OrderBy,
    };
    // We only want this to run once, when the component is first mounted, so an empty array is correct. Try telling that to eslint, though.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reports = useQuery<GetReportIdsQuery, GetReportIdsQueryVariables>(
    gql`
      query getReportIds($filter: ReportSearch!, $order: OrderBy) {
        reports(search: $filter, order: $order) {
          reports {
            id
          }
        }
      }
    `,
    {
      variables: { filter: filter ? { ...filter, category: FormCategory.Reporting } : {}, order },
      skip: !filter,
      onError(error) {
        Sentry.captureException(error);
        console.error(error);
        // Don't alert the user, just don't display the previous/next buttons
        // (which doesn't require any code to make it happen)
      },
    },
  );

  const value = useMemo<FilterContext>(
    () => ({
      search,
      tab,
      reportIdList: reports.data?.reports.reports.map((report) => report.id) ?? null,
      reportIdListLoading: reports.loading,
    }),
    [reports.data, reports.loading, search, tab],
  );

  return <context.Provider value={value}>{children ?? <Outlet />}</context.Provider>;
}

export function useReportListBreadcrumb(): Breadcrumb {
  const { tab, search } = useContext(context);
  return { label: "Report list", url: `/reports/${tab}${search}` };
}

export function useNextPrevious(current: string) {
  const { reportIdList, reportIdListLoading } = useContext(context);
  if (reportIdListLoading) return { loading: true, previous: null, next: null };
  if (!reportIdList) return { loading: false, previous: null, next: null };
  const i = reportIdList.indexOf(current);
  if (i === -1) console.warn("Unexpected report ID");
  return {
    previous: i > 0 ? `/reports/${reportIdList[i - 1]}` : null,
    next: i < reportIdList.length - 1 ? `/reports/${reportIdList[i + 1]}` : null,
  };
}
