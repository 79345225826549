import React from "react";
import { useParams } from "react-router-dom";

import { useOpenableWithTracking } from "../../../../../hooks/useBoolean";
import IconButton from "../../../Button/IconButton";
import { useIsRootEditor } from "../../hooks";
import InsertFileUploadDialog from "./InsertFileUploadDialog";

export default function InsertFileUploadButton() {
  const { open, isOpen, close } = useOpenableWithTracking("Upload file modal");
  const { siteId } = useParams<"siteId" | "organisationId">();

  const isDisabled = !useIsRootEditor();

  return (
    <>
      <IconButton size="small" variant="ghost" onClick={open} icon="file" label="Attach file" disabled={isDisabled} />
      {isOpen ? <InsertFileUploadDialog siteId={siteId!} onCancel={close} /> : null}
    </>
  );
}
