import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { LexicalNode } from "lexical";
import React, { useCallback, useState } from "react";

import Dialog from "../../../../../../shared/components/design-system/Dialog";
import LinkInput from "../../../../../../shared/components/design-system/TextInput/LinkInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import Button from "../../Button";
import IconButton from "../../Button/IconButton";
import { useCommand, useSelectionProperty } from "../hooks";
import { isParentAHeadingNode } from "./utils";

export default function LinkButton() {
  const toggleLink = useCommand(TOGGLE_LINK_COMMAND);
  const { open, close, isOpen } = useOpenableWithTracking("Block Editor Link Modal");
  const [url, setUrl] = useState("");

  const currentLinkUrl = useSelectionProperty(
    (selection) => {
      const nodes = selection?.getNodes() ?? [];
      const firstLink = nodes.find($isLinkNode);
      if (firstLink) return firstLink.getURL();
      for (let node: LexicalNode | null = nodes[0]; node; node = node.getParent()) {
        if ($isLinkNode(node)) return node.getURL();
      }
      return null;
    },
    [],
    null,
  );

  const isDisabled = useSelectionProperty(
    (selection) => {
      if (!selection) return true;
      const allNodes = selection.getNodes();
      if (selection.getTextContent().length === 0 || allNodes.some(isParentAHeadingNode)) return true;
    },
    [],
    true,
  );

  const addLink = useCallback(() => {
    toggleLink({ url });
    close();
  }, [toggleLink, url, close]);

  const removeLink = useCallback(() => {
    toggleLink(null);
    close();
  }, [toggleLink, close]);

  const handleClick = useCallback(() => {
    setUrl(currentLinkUrl ?? "");
    open();
  }, [currentLinkUrl, open, setUrl]);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={close}>
        <SectionHeader title="Add link" />
        <form onSubmit={addLink}>
          <LinkInput label="URL" value={url} onChange={setUrl} required />
          <SectionFooter>
            {currentLinkUrl ? (
              <Button variant="secondary" onClick={removeLink}>
                Remove link
              </Button>
            ) : null}
            <Button variant="ghost" onClick={close} className="ds-ml-auto">
              Cancel
            </Button>
            <Button type="submit">{currentLinkUrl ? "Update link" : "Add link"}</Button>
          </SectionFooter>
        </form>
      </Dialog>
      <IconButton
        size="small"
        variant="ghost"
        onClick={handleClick}
        isActive={!!currentLinkUrl}
        disabled={isDisabled}
        label="Add link"
        icon="link"
      />
    </>
  );
}
