import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
// TODO: any styles needed really ought to be applied by the toast extras system but for now this is fine
import { isAfter } from "date-fns";
import gql from "graphql-tag";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";

import {
  MarkMarketingPromptAsReadMutation,
  MarkMarketingPromptAsReadMutationVariables,
} from "../../../../../__generated__/graphql";
import megaphone from "../../../../../dashboard/src/assets/images/megaphone.svg";
import Alert from "../../../../../shared/components/design-system/Alert";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import styles from "../../../../../shared/components/design-system/Toaster/styles.module.scss";
import useSelf from "../../../hooks/useSelf";

// These could be passed in as props but there's really no need, we only ever make one of these components.
const featureId = "report-download";
// Date format here is (year, month - 1, date):
const showUntil = new Date(2025, 0, 1);
// Configure the body of the alert by editing the component.
let shown = false;

const MARK_MARKETING_PROMPT_AS_READ = gql`
  mutation markMarketingPromptAsRead($featureId: String!) {
    markFeatureAnnouncementAsRead(feature: $featureId) {
      id
      unreadFeatureAnnouncements
    }
  }
`;

export default function useExtraMarketingPrompt() {
  const self = useSelf();
  const toast = useToast();

  const [clearPrompt] = useMutation<MarkMarketingPromptAsReadMutation, MarkMarketingPromptAsReadMutationVariables>(
    MARK_MARKETING_PROMPT_AS_READ,
    { variables: { featureId } },
  );

  useEffect(() => {
    if (shown || !self.unreadFeatureAnnouncements.includes(featureId) || isAfter(new Date(), showUntil)) return;
    shown = true;
    const toastId = toast.showExtra(ExtraMarketingPrompt, {
      onClose: () => {
        clearPrompt().catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
        toast.removeExtra(toastId);
        mixpanel.track("Dismissed the marketing prompt call to action", { featureId });
      },
    });
  }, [clearPrompt, self.unreadFeatureAnnouncements, toast]);
}

function ExtraMarketingPrompt({ onClose }: { onClose: () => void }) {
  return (
    <Alert
      className={styles.toastAlert}
      onClickClose={onClose}
      icon={<img src={megaphone} alt="" width={96} height={88} />}
      title="Report Download Update"
    >
      Reporters can now download a copy of their report upon submission! This highly requested feature empowers them
      with easy access to their reports. Contact your Customer Success Manager if you have any questions.
    </Alert>
  );
}
