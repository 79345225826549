import { isEqual } from "lodash";
import { useState } from "react";

/** Returns a copy of the object passed in which stays reference-equal as long as the input stays value-equal. */
export default function useDeepEquality<T>(current: T) {
  const [last, setLast] = useState(current);
  if (!isEqual(current, last)) {
    setLast(current);
    return current;
  }
  return last;
}
