import React, { FormEvent, useCallback, useEffect, useState } from "react";

import ButtonLink from "../../../../../shared/components/design-system/Button/ButtonLink";
import Divider from "../../../../../shared/components/design-system/Divider";
import TextInput from "../../../../../shared/components/design-system/TextInput";
import PasswordInput from "../../../../../shared/components/design-system/TextInput/PasswordInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import useStateWithInputChange from "../../../hooks/useStateWithInputChange";
import { isLocalStorageAvailable } from "../../../utils";
import Button from "../../design-system/Button";
import { SignInCard, SignInMain } from "../SignInPage/containers";
import ChooseSso from "./ChooseSso";

interface SignInFormProps {
  onSignIn: (username: string, password: string) => void;
  isSigningIn: boolean;
  toast: string | null;
  startForgotPassword: () => void;
}

export default function SignInForm({ onSignIn, isSigningIn, toast, startForgotPassword }: SignInFormProps) {
  const [username, changeUsername] = useStateWithInputChange("");
  const [password, changePassword] = useState("");
  const [usingSso, setUsingSso] = useState(false);
  const openSso = useCallback(() => setUsingSso(true), []);
  const closeSso = useCallback(() => {
    if (isLocalStorageAvailable()) localStorage.setItem("ssoProvider", "");
    setUsingSso(false);
  }, []);

  const onSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (username && password) {
        onSignIn(username, password);
      }
    },
    [onSignIn, password, username],
  );

  useEffect(() => {
    if (localStorage.getItem("ssoProvider")) {
      openSso();
    }
  }, [openSso]);

  if (usingSso) {
    return <ChooseSso onClose={closeSso} toast={toast} />;
  }

  return (
    <SignInMain>
      <SignInCard toast={toast}>
        <form onSubmit={onSubmit}>
          <TextInput
            id="email"
            type="email"
            label="Email"
            autoComplete="username"
            autoFocus
            required
            value={username}
            onChange={changeUsername}
            hideValidation
          />
          <PasswordInput label="Password" required value={password} onChange={changePassword} hideValidation />
          <ButtonSet direction="vertical" className="ds-mt-7">
            <Button type="submit" size="large" loading={isSigningIn}>
              Sign in with email
            </Button>
            <Divider label="or" />
            <Button onClick={openSso} variant="secondary" size="large">
              Sign in with SSO
            </Button>
          </ButtonSet>
        </form>
      </SignInCard>
      <ButtonLink isQuiet isOverBg className="ds-mt-7" variant="secondary" onClick={startForgotPassword}>
        Forgotten password?
      </ButtonLink>
    </SignInMain>
  );
}
